// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Karla:wght@700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody,\n#root {\n  height: 100%;\n}\n::-webkit-scrollbar {\n  width: 0px;\n  background: transparent;\n}\nbody {\n  font-family: 'Inconsolata', monospace;\n  font-weight: 300;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n*::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n* {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n", "",{"version":3,"sources":["webpack://./src/Frontend/Styles/style.css"],"names":[],"mappings":"AAIA;;;EAGE,YAAY;AACd;AACA;EACE,UAAU;EACV,uBAAuB;AACzB;AACA;EACE,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Karla:wght@700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');\n\nhtml,\nbody,\n#root {\n  height: 100%;\n}\n::-webkit-scrollbar {\n  width: 0px;\n  background: transparent;\n}\nbody {\n  font-family: 'Inconsolata', monospace;\n  font-weight: 300;\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n*::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n* {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
